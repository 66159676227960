// import { User } from "../../lib/user"
// import { MsalUser, MsalUserManager, MsalLoginError } from './../user/msal/manager'
// import { LionUser, LionUserManager, LionUserLoginData, LionLoginError } from './../user/lion/manager'
// import LionAuthenticationManager, { authStream, AuthDetails, AuthSubscriberActions } from '../user/lion/auth';
import axios from 'axios';

import User, {
    MsalUser,
    MsalUserManager,
    LionUser,
    LionUserManager,
    LionUserLoginData,
    DefaultConfigs,
    AuthSubscriberActions,
    authStream,
    AuthDetails
} from 'user-manager';

import { HttpClient } from 'user-manager/build/user/common';
import { get as apiGet, post as apiPost , put as apiPut } from '../api';

class TestClient implements HttpClient{
    get: any
    post: any
    put: any
    patch: any
    delete: any
    constructor() {
        this.get = apiGet;
        this.post = apiPost;
        this.put = apiPut;
    }
}

const clientId = 'b4ae158e-b9b9-4c88-a774-8e46f8ac85a3';
const authority = 'https://login.microsoftonline.com/c01fc043-28aa-4bf4-b2df-6feb850db29a'

export const userManager = new MsalUserManager({...DefaultConfigs.createMsalConfig(), msalConfig: {
    auth: {
        clientId: clientId,
        authority: authority
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
}});

export const lionUserManager = new LionUserManager(DefaultConfigs.createLionConfig(new TestClient()));

export const Repo = (state = { user: lionUserManager.user }, action: { type: UserActionTypes, payload: User }) => {
    switch (action.type) {
        case UserActionTypes.SET_USER: {
            return { user: action.payload };
        }
        case UserActionTypes.GET_USER: return state
        default: return state;
    }
}

const _refreshToken = async (user: LionUser) => {
    console.log('Token refreshed')
    console.log({ user })

    /// set global USER variable
    lionUserManager.user = user;

    /// save USER locally
    lionUserManager.localUserManager.setData(user);

    /// broadcast USER
    authStream.next(new AuthDetails({ isAuthentic: true, user: user, action: AuthSubscriberActions.DO_NOTHING }))
}

/// runtime assign new-auth handler
lionUserManager.authenticationManager.onAuthRefresh = _refreshToken;

export const loginAsLionUserManually = async (): Promise<User> => {

    /// clear local USER
    lionUserManager.localUserManager.clearData();

    /// get USER credentials from msal
    const msalUser: MsalUser = await userManager.loginManager.logIn();

    // print({msalUser})

    const lionUserLoginData: LionUserLoginData = {
        password: msalUser.rawTokenId,
        userName: msalUser.data.userName
    };

    return _login(lionUserLoginData);
}

export const loginAsLionUserAuto = async (): Promise<User> => {

    /// clear local USER
    lionUserManager.localUserManager.clearData();

    /// get USER credentials from msal
    const msalUser: MsalUser = await userManager.loginManager.autoLogin();

    console.log({msalUser});

    const lionUserLoginData: LionUserLoginData = {
        password: msalUser.rawTokenId,
        userName: msalUser.data.userName
        // userName: "admin"
    };

    return _login(lionUserLoginData);
}

const _login = async (loginData: LionUserLoginData) => {

    /// get USER by login
    const lionUser: LionUser = await lionUserManager.loginManager.logIn(loginData);

    /// set global USER variable
    lionUserManager.user = lionUser;

    /// save USER locally
    lionUserManager.localUserManager.setData(lionUser);

    /// broadcast USER
    authStream.next(new AuthDetails({ isAuthentic: true, user: lionUser }))

    return lionUser;
}

export const logOutAsLionUser = () => {
    lionUserManager.logOutManager.logOut();
    lionUserManager.localUserManager.clearData();
    userManager.logOutManager.logOut();
    authStream.next(new AuthDetails({ isAuthentic: false, action: AuthSubscriberActions.DO_NOTHING }))
}

export const UserRepoActions = (dispatch: any) => ({
    setStoreUser: (user: LionUser) => {
        dispatch({ type: UserActionTypes.SET_USER, payload: user });
    }
})

export class UserActionTypes {
    static SET_USER = 'SET_USER'
    static GET_USER = 'GET_USER'
}