import * as Msal from "msal";
import { MsalUserConfig } from './manager';

export default class MsalConfig implements MsalUserConfig{
    msalInstance: Msal.UserAgentApplication;
    msalConfig: Msal.Configuration;
    requestObj: {};

    //personal
    // clientId = '9ef62b71-a31b-45c3-98f2-5745b53307e4';

    //eimsky(testing)
     clientId = 'b4ae158e-b9b9-4c88-a774-8e46f8ac85a3';

    //lion(production)
    //clientId = '00b08bbb-6cce-4aa4-95a0-652e2e6a44a4';

    localStorageAccountKey: string;

    //eimsky(testing)
     authority = 'https://login.microsoftonline2.com/c01fc043-28aa-4bf4-b2df-6feb850db29a'
    
    //lion(production)
    //authority = 'https://login.microsoftonline.com/5b3db9ce-9638-4635-aae8-3caafb4d960f'

    /**
     *
     */
    constructor() {
        this.msalConfig = {
            auth: {
                clientId: this.clientId,
                authority: this.authority
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        }; 

        this.requestObj = {
            scopes: ["user.read"]
        }

        this.localStorageAccountKey = "masl.user"

        this.msalInstance = new Msal.UserAgentApplication(this.msalConfig);

        this.msalInstance.handleRedirectCallback((error) => {
          console.log(error);
        });
    }
}
