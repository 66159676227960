const auth = btoa("OAUTH_DEMO_CLIENT_ID:hello");

// var _baseUrl = "http://localhost:3000";

 //var _baseUrl = "https://lionapi.eimsky.com";
var _baseUrl = "https://awdemoapi.eimsky.com"

if (process.env.NODE_ENV == "production") {
	// change this when going production
	//  _baseUrl = "localhost:8762"
	 //_baseUrl = "https://lionapi.eimsky.com";
	 _baseUrl =  "https://awdemoapi.eimsky.com";
	// _baseUrl = "https://13.89.41.122:9762"
}

export const baseUrl = _baseUrl;

// export const imgBaseUrl = "http://40.83.12.134:9762/artwork/downloadFile/";
// export const imgBaseUrl = "http://172.16.0.169:8762/artwork/downloadFile/";
// export const imgBaseUrl = "https://lionapi.eimsky.com/artwork/downloadFile/";
export const imgBaseUrl = `${_baseUrl}/artwork/downloadFile/`;

export const orgImageBaseUrl =
	`${_baseUrl}/artwork/downloadFile/original/`;
// export const imgBaseUrl = "https://13.89.41.122:9762/artwork/downloadFile/";

export const headers = {
	"Content-Type": "application/javascript",
	Authorization: "Basic " + auth,
	"cache-control": "no-cache"
};
// "proxy": "http://10.253.162.185:8762",
// "proxy": "http://172.16.0.169:8762",
