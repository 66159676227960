import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
	GlobalFilterActions,
	Filters,
	IFilter,
} from "../repos/GlobalFilterRepo";
import { lionUserManager } from "../repos/UserRepo";
import {
	message,
	Form,
	Upload,
	Button,
	Input,
	Typography, 
} from "antd";
import { AxiosResponse, AxiosInstance } from "axios";
import { texts } from "../texts.config";
import { withRouter } from "react-router";
import {
	FolderOutlined,
	SubnodeOutlined,
	FolderOpenOutlined,
	LoadingOutlined,
	CheckOutlined,
	ExclamationOutlined,
} from "@ant-design/icons";
import {
	UploaderState,
	FileDataMapper,
	SingleFileUploader,
	MultipleFileUploader,
	defaultImageExtensions,
} from "../components";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { CategoryFilter } from "../components";
import { INode } from "../components";
import { Select } from "antd";

const { Option } = Select;

const { Title, Paragraph, Text } = Typography;
interface CreateArtWorkPageState {
	file: RcFile | undefined;
	filterData: [];
	description: string;
	reason: string;
	fileMapperData: UploaderState;
	isLimitedEdition: boolean;
	validation: {
		filters: boolean;
		reason: boolean;
		comment: boolean;
		file: boolean;
	};
}
interface ValidationLog {
	isValid: boolean;
	category: string;
}

const imageExtensions = [".png", ".jpeg", ".jpg", ".PNG", ".JPG", ".JPEG"];

class CreateArtWorkPage extends React.Component<any, CreateArtWorkPageState> {
	constructor(props: any) {
		super(props);
		this.onMultipleUploaderSubmit = this.onMultipleUploaderSubmit.bind(this);
		this.validate = this.validate.bind(this);
		this.onDescriptionChange = this.onDescriptionChange.bind(this);
		this.onReasonChange = this.onReasonChange.bind(this);
		this.validateFilter = this.validateFilter.bind(this);
		this.state = {
			file: undefined,
			filterData: [],
			description: "",
			reason: "",
			isLimitedEdition: false,
			fileMapperData: {
				files: [],
				data: [],
			},
			
			validation: {
				filters: false,
				reason: true,
				comment: true,
				file: false,
			},
		};
	}

	async syncFilters() {
		await this.props.syncFilters();
	}

	versionTypeChange = (value: any) => {
		value == 1
			? this.setState({ isLimitedEdition: true })
			: this.setState({ isLimitedEdition: false });
	};

	validate() {
		// var is = false;
		// this.props.GlobalFilterRepo.forEach((repo: IFilter<Array<INode<any>>>) => {
		//     if (!repo.active[repo.active.length - 1].isLeaf) {
		//         is = false
		//         return
		//     }
		// })

		// console.log(is)

		return (
			this.state.file && this.validateFilter(this.props.GlobalFilterRepo).isValid
		);
	}

	validateFilter(repo: Array<IFilter<Array<INode<any>>>>): ValidationLog {
		// let x = repo.map((filter: IFilter<Array<INode<any>>>) =>
		//     filter.active.map((node: INode<any>) => node.isLeaf)
		// );

		//get is "Export" is selected
		var isExportSelected = repo[0].active.find((node) => node.id === 16);

		if (isExportSelected) {
			//check all leafs selected
			var shouldCount = repo.length;
			var selectedLeafCount = repo
				.map((filter) => filter.active.filter((node) => node.isLeaf).length)
				.reduce((p, c) => p + c, 0);
			return { isValid: shouldCount == selectedLeafCount, category: "_category" };
		} else {
			// without BDM leaf
			var shouldCount = repo.length - 1;

			var selectedLeafCount = repo
				/// [filter.id !== 3] is to discard BDM section
				.map((filter) => filter.active.filter((node) => filter.id !== 3 && node.isLeaf).length)
				.reduce((p, c) => p + c, 0);

			return { isValid: shouldCount == selectedLeafCount, category: "_category" };
		}
	}

	async _createArtwork() {
		var hide;
		try {
			hide = message.loading("Action in progress..", 0);

			const activeProcessedFilterCollection = this.props.GlobalFilterRepo.map(
				(filter: IFilter<Array<INode<any>>>) => this._convert(filter.active)
			);
			const activeProcessedFlatFilterCollection = _.flatten(
				activeProcessedFilterCollection
			);

			console.log({
				processed: activeProcessedFlatFilterCollection,
				raw: activeProcessedFilterCollection,
				repo: this.props.GlobalFilterRepo,
			});

			if (this.validate()) {
				const form = new FormData();

				if (this.state.file) form.append("artwork", this.state.file);

				this.state.fileMapperData.files.forEach((element: any) => {
					form.append("imageList", element);
				});

				form.append(
					"imageDetails",
					JSON.stringify(
						this.state.fileMapperData.data.map((data: FileDataMapper) => ({
							thumbnail: data.file,
							comment: data.comment,
							imageUrl: data.url,
						}))
					)
				);

				form.append(
					"mainVersion",
					JSON.stringify({
						status: { id: 1 },
						mainVersionCategories: activeProcessedFlatFilterCollection,
						description: this.state.description,
						reason: this.state.reason,
						is_main_version_lock: true,
						is_active: true,
						user_id: lionUserManager.user.data.USER_ID,
						isLimitedEdition: this.state.isLimitedEdition,
					})
				);

				const res: AxiosResponse<any> = await lionUserManager.authenticationManager.post(
					"/artwork/mainVersion/create",
					form
				);

				if (res.status == 200) {
					message.success(texts.ARTWORK_CREATION_SUCCESS);

					const { history } = this.props;
					history.push(`view/${res.data.id}`);
				} else {
					console.log("Create Error Log", res);
					throw new Error(texts.ARTWORK_CREATION_ERROR);
				}
			} else {
				message.warn("Please check inputs");
			}
		} catch (err) {
			if (err.code === "ECONNABORTED") message.error(texts.ARTWORK_TIMEOUT_ERROR);
			else message.error(err.message);
		} finally {
			hide && hide();
		}
	}

	_convert(e: any[]) {
		return e
			.filter(({ data }) => {
				const { type, masterData } = data;
				if (data != null)
					if (type.id != undefined && masterData.id != undefined) return true;
			})
			.map(({ data }) => {
				const { type, masterData, id } = data;
				return {
					category: {
						id: type.id,
					},
					categoryMasterData: {
						id: id,
					},
				};
			});
	}

	_onHistoryChange(e: any[], index: number) {
		const converted = this._convert(e);
		// this.state.filterData[index] = converted;
		this.setState({
			filterData: this.state.filterData,
		});
		this.props.setFilter(_.flatMap(this.state.filterData));
	}

	onMultipleUploaderSubmit(data: UploaderState) {
		this.setState({
			fileMapperData: data,
		});
	}

	onDescriptionChange(value: string) {
		if (!(value.length > 250))
			this.setState({
				description: value,
			});
		else message.warn("Description is too large");
	}

	onReasonChange(value: string) {
		this.setState({
			reason: value,
		});
	}

	render() {
		const props = {
			onRemove: (file: any) => {
				this.setState({ file });
			},
			beforeUpload: (file: any) => {
				if (this.state.file == undefined) {
					this.setState({ file });
				} else {
					message.error("You Can Not Upload Multyple Files..");
				}

				return false;
			},
			accept: ".jpeg,.png",
			multiple: false,
		};
		// const mainCats = normalizeMainCat(this.props.GlobalFilterRepo.filters).map(normalize);
		return (
			<Form className='ant-advanced-search-form'>
				{/* {
                    mainCats && mainCats.map((e: any, i: number) =>
                        <Form.Item key={i}>
                            <CategoryFilter key={i} data={e} onHistoryChange={(_e: any) => this._onHistoryChange(_e, i)} />
                        </Form.Item>
                    )
                } */}

				
				<CategoryFilter {...this.props} />
				<Select
					defaultValue='0'
					style={{ width: 320 }}
					onChange={this.versionTypeChange}>
					<Option value='0'>Regular Design</Option>
					<Option value='1'>Limited edition</Option>
				</Select>
				<Input.TextArea
					style={{ marginTop: 10, marginBottom: 10 }}
					placeholder='Reason'
					rows={4}
					onChange={({ target: { value } }) => this.onReasonChange(value)}
				/>
				<Input.TextArea
					style={{ marginTop: 10, marginBottom: 10 }}
					placeholder='Description'
					rows={4}
					onChange={({ target: { value } }) => this.onDescriptionChange(value)}
				/>

				<Form.Item>
					<div>
						{/* <Upload.Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single file upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </Upload.Dragger>   */}

						<SingleFileUploader
							text='Upload main artwork thumbnail'
							accept={imageExtensions}
							onChange={(file: any) => this.setState({ file })}
						/>

						<div style={{ height: 10 }}></div>
						<Text strong>* Related Files (JPG , PNG)</Text>

						<MultipleFileUploader
							text='Upload other related documents - Ex: body, neck'
							accept={imageExtensions}
							onChange={this.onMultipleUploaderSubmit}
						/>
					</div>
				</Form.Item>
				<Form.Item>
					<Button
						type='primary'
						shape='round'
						htmlType='submit'
						onClick={this._createArtwork.bind(this)}>
						Create Artwork
					</Button>
				</Form.Item>
			</Form>
		);
	}
}

// const withFilter = connect((state => state), UserRepoActions)(_CreateArtWorkPage);
export default withRouter(
	connect((state) => state, GlobalFilterActions)(CreateArtWorkPage) as React.FC
);
