import { UserAuthenticationManager } from "../../lib/user";
import { Subject, Subscription } from 'rxjs';
import { LionUser } from './manager';
// import { AxiosResponse, AxiosError } from 'axios';
import { WithConfig } from "../../lib/util/withConfig";
import LionConfig from "./config";
import { HttpClientError, HttpClientResponse, HttpClient } from '../common'


export class AuthDetailSubscription extends Subscription { }

export class AuthSubscriberActions {
    static REFRESH_TOKEN = 'REFRESH_TOKEN';
    static DO_NOTHING = 'DO_NOTHING';
}

export class AuthDetails {
    isAuthentic: boolean;
    action?: AuthSubscriberActions;
    user?: LionUser;
    pendingJob?: any;
    constructor({ isAuthentic = false, user = new LionUser(), action = AuthSubscriberActions.DO_NOTHING, pendingJob = undefined }) {
        this.isAuthentic = isAuthentic;
        this.user = user;
        this.action = action;
        this.pendingJob = pendingJob;
    }
}

/// AUTH STREAM
/// global stream that transport auth details
const authStream = new Subject<AuthDetails>();
export { authStream };

export default class LionAuthenticationManager extends WithConfig<LionConfig> implements UserAuthenticationManager {
    parentServices: any;
    authDetails?: AuthDetails;
    onAuthRefresh?: (newUser: LionUser) => void
    constructor(parentServices: LionUser, _config: LionConfig, onAuthRefresh?: (newUser: LionUser) => void) {
        super(_config);
        authStream.subscribe(this.onStream.bind(this))
        this.refreshAuth = this.refreshAuth.bind(this)
        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.parentServices = parentServices;
        this.onAuthRefresh = onAuthRefresh;
        ///this must remove
        // this.authDetails = {...this.authDetails, isAuthentic: true};
    }

    onStream(details: AuthDetails) {
        this.authDetails = details
        switch (details.action) {
            case AuthSubscriberActions.REFRESH_TOKEN: this.refreshAuth(details)
        }
    }

    checkPermission(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.authDetails)
                resolve(this.authDetails.isAuthentic)
            else resolve(false)
        });
    }

    setToUnauthorized() {
        authStream.next({ ...this.authDetails, isAuthentic: false })
    }

    setToAuthorized() {
        authStream.next({ ...this.authDetails, isAuthentic: true })
    }

    async refreshAuth(details: AuthDetails) {

        try {
            if (details.user) {
                const form: FormData = new FormData();

                form.append('grant_type', 'refresh_token')
                form.append('refresh_token', details.user.data.refresh_token)

                let _lionAuthResp: HttpClientResponse = await this.config.httpClient.post("/oauth/token", form, {});

                const user = new LionUser(_lionAuthResp.data, details.user.name)

                if (this.onAuthRefresh)
                    this.onAuthRefresh(user);

            } else {
                throw new Error()
            }
        }
        catch (err) {
            this.setToUnauthorized()
        };
    }

    _onHttpError(data: HttpClientError, that: any) {
        // console.dir(data)
        if (data.response) {
            const { status } = data.response;
            switch (status) {
                case 401: authStream.next(new AuthDetails({
                    ...this.authDetails,
                    action: AuthSubscriberActions.REFRESH_TOKEN,
                    isAuthentic: false,
                    pendingJob: that
                }))
                default: authStream.next(new AuthDetails({ ...this.authDetails, action: AuthSubscriberActions.DO_NOTHING, isAuthentic: true }))
            }
        }
    }

    async get(url: string) {
        try {
            if (this.authDetails && this.authDetails.user)
                return await this.config.httpClient.get(url, { Authorization: "Bearer " + this.authDetails.user.data.access_token })
        } catch (err) {
            this._onHttpError(err, this)
            throw err;
        }
    }

    async post(url: string, body: {}) {
        try {
            if (this.authDetails && this.authDetails.user)
                return await this.config.httpClient.post(url, body, { Authorization: "Bearer " + this.authDetails.user.data.access_token })
        } catch (err) {
            this._onHttpError(err, this)
            throw err;
        }
    }

    async call(method: keyof HttpClient, url: string, body: {}){
        try {
            if (this.authDetails && this.authDetails.user)
                return await this.config.httpClient[method](url, body, { Authorization: "Bearer " + this.authDetails.user.data.access_token })
        } catch (err) {
            this._onHttpError(err, this)
            throw err;
        }        
    }

    //@override
    authenticate() {
        return new Promise<any>(async (resolve) => {
            resolve('eee')
        });
    }
}