export interface CanLogOut<T> {
    logOutManager: UserLogOutManager<T>
}

export interface CanLogIn<T> {
    loginManager: UserLoginManager<T>;
}

export interface UserManager {
    user: User;
    config: UserAuthorityConfig
    localUserManager: LocalUserManager;
    // authenticationManager: UserAuthenticationManager;
}

export interface UserLoginManager<T> {
    config: UserAuthorityConfig
    logIn(loginData:any): T;
}

export interface UserLogOutManager<T> {
    config: UserAuthorityConfig
    logOut(): T;
}

export interface User {
    data?: any;
}

export interface LocalUserManager {
    config: UserAuthorityConfig
    setData(data?: any): any;
    getData: any;
}

export interface UserAuthorityConfig { }

export interface UserAuthenticationManager{
    config: UserAuthorityConfig
    refreshAuth(detail?: any): any;
    authenticate(job: any| undefined): any
}

// export function withToken(accessToken: string): any;

export abstract class Authenticate{
    /**
     *
     */
    // authenticationManager: UserAuthenticationManager;
    // constructor(_authenticationManager: UserAuthenticationManager) {
    //     this.authenticationManager = _authenticationManager;
    // }
    
}

export interface UserLoginError<T>{
    message: string,
    payload: T | undefined
}